import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import RecipeForm from './recipe/RecipeForm';
import withRouter from '../utils/withRouter';

class EditRecipe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipe: {
        id: '',
        title: '',
        ingredients: [],
        instructions: [],
      }
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount = async () => {
    const { id } = this.props.router.params;

    const response = await fetch(`/api/v1/recipes/${id}`);
    if (response.ok) {
      const recipe = await response.json();
      recipe.instructions = recipe.instructions.sort(instruction => instruction.step).map(instruction => instruction.description);

      this.setState({ recipe })
    }
  }

  async onSubmit(values) {
    const token = document.querySelector(`meta[name="csrf-token"]`).content;
    const response = await fetch(`/api/v1/recipes/${this.state.recipe.id}`, {
      method: 'PUT',
      headers: {
        'x-csrf-token': token,
        'content-type': 'application/json'
      },
      body: JSON.stringify(values)
    });

    if (response.ok) {
      const { id } = await response.json()
      this.props.router.navigate(`/recipe/${id}`)
    }
  }

  render() {
    return (
      <div className="container mt-5 mb-3">
        <div className="row col-sm-12 col-lg-6 offset-lg-3">
          <h1 className="fw-light mb-3">
            Edit recipe
          </h1>
        </div>
        <RecipeForm onSubmit={this.onSubmit} initialValues={this.state.recipe} />
        <Link to={`/recipe/${this.state.recipe.id}`} className="btn btn-link mt-3 ps-1">Cancel</Link>
      </div>
    );
  }
}

export default withRouter(EditRecipe);
