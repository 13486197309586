import React from 'react';
import { Link } from 'react-router-dom';

class Recipes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recipes: []
        }
    }

    async componentDidMount() {
        const response = await fetch("/api/v1/recipes/");
        if (response.ok) {
            this.setState({ recipes: await response.json() });
        }
    }

    render() {
        const { recipes } = this.state;
        const allRecipes = recipes.map((recipe, index) => (
            <div key={index} className="col-md-6 col-lg-4">
                <div className="card mb-4">
                    <img src={recipe.image} alt={`${recipe.title} image`} />
                    <div className="card-body">
                        <h5 className="card-title"></h5>
                        <Link to={`/recipe/${recipe.id}`} className="btn custom-button">
                            View Recipe
                        </Link>
                    </div>
                </div>
            </div>
        ));

        const noRecipes = (
            <div className="vw-100 vh-50 d-flex align-items-center justify-content-center">
                <h4>
                    No recipes yet. Why not <Link to="/recipe/new">create one</Link>?
                </h4>
            </div>
        );

        return (
            <>
                <section className="jumbotron jumbotron-fluid text-center">
                    <div className="container py-5">
                        <h1 className="display-4">Recipes for every occasion!</h1>
                        <p className="lead text-muted">
                            We've pulled together our most popular recipes, our latest
                            additions, and our editor's picks, so there's sure to be something
                            tempting for you to try.
                        </p>
                    </div>
                </section>
                <div className="py-5">
                    <main className="container">
                        <div className="text-right mb-3">
                            <Link className="btn custom-button" to="/recipe/new">
                                Create New Recipe
                            </Link>
                        </div>
                        <div className="row">
                            {recipes.length > 0 ? allRecipes : noRecipes}
                        </div>
                        <Link to="/" className="btn btn-link">
                            Home
                        </Link>
                    </main>
                </div>
            </>
        );
    }
}

export default Recipes;
