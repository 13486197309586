import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { Component } from 'react';
import * as Yup from 'yup';

export default class RecipeForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = props.onSubmit;
    this.initialValues = {
      title: '',
      description: '',
      ingredients: [{ name: '', quantity: '', unit: '' }],
      instructions: ['']
    };
    this.validationSchema = Yup.object({
      title: Yup.string().label('Title').min(5).matches(/[a-zA-Z]/, 'Must contain a letter').max(80).required(),
      description: Yup.string().label('Description').max(300),
      ingredients: Yup.array().of(Yup.object({
        name: Yup.string().min(2, 'Too short').matches(/[a-zA-Z]/, 'Must contain a letter').max(100, 'Too long').required('Name is required'),
        quantity: Yup.number().min(0, 'Must be positive').max(1000).test(
          'valid-decimal',
          'Enter a decimal',
          value => `${value}`.match(/^\d{0,4}(\.\d{0,3})?$/)
        ),
        unit: Yup.string().matches(/[a-zA-Z]/, 'Must contain a letter').max(20, 'Too long')
      })).min(2, 'At least two ingredients required'),
      instructions: Yup.array().of(
        Yup.string().min(5, 'Too short').matches(/[a-zA-Z]/, 'Must contain a letter').max(500, 'Too long').required('Step description cannot be blank')
      ).min(2, 'At least two instructions required')
    });
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={this.props.initialValues || this.initialValues}
        validationSchema={this.validationSchema}
        onSubmit={this.onSubmit}
        validateOnChange={false}
      >
        {({ values, errors, touched }) => (
          <Form className="form-group">
            <div className="form-group my-1">
              <label className="form-label" htmlFor="title">Title</label>
              <Field className={`form-control ${touched.title && errors.title && 'is-invalid'}`} name="title" type="text" />
              <ErrorMessage name="title" component="div" className="invalid-feedback" />
            </div>

            <div className="form-group my-1">
              <label className="form-label" htmlFor="description">Description</label>
              <Field className={`form-control ${touched.description && errors.description && 'is-invalid'}`} name="description" as="textarea" />
              <ErrorMessage name="description" component="div" className="invalid-feedback" />
            </div>

            <div className="form-group my-1 mt-3">
              <label className="form-label" htmlFor="ingredients"><h4 className="fw-normal">Ingredients</h4></label>
              <FieldArray name="ingredients">
                {({ remove, push }) => (
                  <div>
                    {values.ingredients.length > 0 &&
                      values.ingredients.map((_ingredient, index) => (
                        <div className="row my-1 align-items-center" key={index}>
                          <div className="col">
                            <label className="form-label" htmlFor={`ingredients[${index}].name`}>Name</label>
                            <Field
                              className={
                                `form-control ${touched.ingredients?.[index]?.name
                                && errors.ingredients?.[index]?.name && 'is-invalid'}`
                              }
                              name={`ingredients[${index}].name`}
                              placeholder="Salt"
                              type="text"
                            />
                            {
                              touched.ingredients && Array.isArray(errors.ingredients) &&
                              <ErrorMessage
                                name={`ingredients[${index}].name`}
                                component="div"
                                className="invalid-feedback"
                              />
                            }
                          </div>
                          <div className="col">
                            <label className="form-label" htmlFor={`ingredients[${index}].quantity`}>Amount</label>
                            <Field
                              className={
                                `form-control ${touched.ingredients?.[index]?.quantity
                                && errors.ingredients?.[index]?.quantity && 'is-invalid'}`
                              }
                              name={`ingredients[${index}].quantity`}
                              placeholder="1"
                              type="number"
                            />
                            {
                              touched.ingredients && Array.isArray(errors.ingredients) &&
                              <ErrorMessage
                                name={`ingredients[${index}].quantity`}
                                component="div"
                                className="invalid-feedback"
                              />
                            }
                          </div>
                          <div className="col">
                            <label className="form-label" htmlFor={`ingredients[${index}].unit`}>Unit</label>
                            <Field
                              className={
                                `form-control ${touched.ingredients?.[index]?.unit
                                && errors.ingredients?.[index]?.unit && 'is-invalid'}`
                              }
                              name={`ingredients[${index}].unit`}
                              placeholder="tsp"
                              type="text"
                            />
                            {
                              touched.ingredients && Array.isArray(errors.ingredients) &&
                              <ErrorMessage
                                name={`ingredients[${index}].unit`}
                                component="div"
                                className="invalid-feedback"
                              />
                            }
                          </div>
                          <div className="col mt-4">
                            <button
                              className="btn btn-close"
                              type="button"
                              onClick={() => remove(index)}
                            />
                          </div>
                        </div>
                      ))
                    }
                    <button
                      type="button"
                      className="btn btn-secondary mt-2"
                      onClick={() => push({ name: "", quantity: '', unit: '' })}
                    >
                      Add Another Ingredient
                    </button>
                    {
                      touched.ingredients && typeof errors.ingredients === 'string' &&
                      <div className="invalid-feedback" style={{ display: 'block' }}>{errors.ingredients}</div>
                    }
                  </div>
                )}
              </FieldArray>
            </div>

            <div className="form-group my-1 mt-3">
              <label className="form-label" htmlFor="instructions"><h4 className="fw-normal">Instructions</h4></label>
              <FieldArray name="instructions">
                {({ remove, push }) => (
                  <div>
                    {values.instructions.length > 0 &&
                      values.instructions.map((_instruction, index) => (
                        <div className="row my-1 align-items-center" key={index}>
                          <div className="col">
                            <label className="form-label" htmlFor={`instructions[${index}]`}>
                              {`Step ${index + 1}`}
                            </label>
                            <Field
                              className={
                                `form-control ${touched.instructions &&
                                Array.isArray(errors.instructions) &&
                                errors.instructions?.[index] &&
                                touched.instructions[index] && 'is-invalid'}`
                              }
                              name={`instructions[${index}]`}
                              placeholder="Preheat oven to 350."
                              as="textarea"
                            />
                            {
                              touched.instructions && Array.isArray(errors.instructions) && errors.instructions?.[index] &&
                              <ErrorMessage
                                name={`instructions[${index}]`}
                                component="div"
                                className="invalid-feedback"
                              />
                            }
                          </div>
                          <div className="col mt-4">
                            <button
                              className="btn btn-close"
                              type="button"
                              onClick={() => remove(index)}
                            />
                          </div>
                        </div>
                      ))
                    }
                    <button
                      type="button"
                      className="btn btn-secondary mt-2"
                      onClick={() => push('')}
                    >
                      Add Another Instruction
                    </button>
                    {
                      touched.instructions && typeof errors.instructions === 'string' &&
                      <div className="invalid-feedback" style={{ display: 'block' }}>{errors.instructions}</div>
                    }
                  </div>
                )}
              </FieldArray>
            </div>

            <button className="btn btn-primary mt-3" type="submit">Submit</button>
          </Form>
        )}
      </Formik>
    );
  }
};
