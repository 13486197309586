import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EditRecipe from '../components/EditRecipe';
import Home from '../components/Home';
import NewRecipe from '../components/NewRecipe';
import Recipe from '../components/Recipe';
import Recipes from '../components/Recipes';

export default (
    <Router>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/recipes" element={<Recipes/>} />
            <Route path="/recipe/:id" element={<Recipe/>} />
            <Route path="/recipe/:id/edit" element={<EditRecipe/>} />
            <Route path="/recipe/new" element={<NewRecipe/>} />
        </Routes>
    </Router>
);
