import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import withRouter from '../utils/withRouter';

class Recipe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipe: {
        id: '',
        title: '',
        description: '',
        ingredients: [],
        instructions: [],
      }
    };
  }

  async componentDidMount() {
    const { id } = this.props.router.params;

    const response = await fetch(`/api/v1/recipes/${id}`);
    if (response.ok) {
      this.setState({ recipe: await response.json() })
    }
  }

  deleteRecipe = async () => {
    const { id } = this.props.router.params;

    const token = document.querySelector(`meta[name="csrf-token"]`).content;
    const response = await fetch(`/api/v1/recipes/${id}`, {
      method: 'DELETE',
      headers: {
        'x-csrf-token': token,
        'content-type': 'application/json'
      },
    });

    if (response.ok) {
      this.props.router.navigate(`/recipes`)
    }
  }


  render() {
    const { recipe } = this.state;

    return (
      <div>
        <div className="hero position-relative d-flex align-items-center justify-content-center">
          <img
            src={recipe.image}
            alt={`${recipe.title} image`}
            className="img-fluid position-absolute"
          />
          <div className="overlay bg-dark position-absolute" />
          <h1 className="display-4 position-relative text-white">
            {recipe.title}
          </h1>
        </div>
        <div className="container-lg mb-3">
          <div className="row justify-content-center">
            <div className="col-lg-4 p-5 px-lg-0">
              <div className="lead text-center">{recipe.description}</div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-4 col-xl-2 mb-2">
              <ul className="list-group list-group-flush">
                <h5>Ingredients</h5>
                {recipe.ingredients.length > 0 &&
                  recipe.ingredients.map((ingredient, index) => (
                    <li key={index} className="list-group-item">
                      {ingredient.quantity} {ingredient.unit} {ingredient.name}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="col-sm-8 col-xl-7">
              <h5>Instructions</h5>
              <ol className="list-group list-group-flush list-group-numbered">
                {recipe.instructions.length > 0 &&
                  recipe.instructions.map((instruction, index) => (
                    <li key={index} className="list-group-item">
                      {instruction.description}
                    </li>
                  ))}
              </ol>
            </div>
          </div>
          <div className="row justify-content-around mt-5">
            <div className="col col-xl-3 text-nowrap">
              <Link to="/recipes" className="btn btn-link">
                Back to recipes
              </Link>
            </div>
            <div className="col col-xl-3 text-center">
              <Link to={`/recipe/${recipe.id}/edit`} className="btn btn-link">
                Edit Recipe
              </Link>
            </div>
            <div className="col col-xl-3 text-end">
              <button type="button" className="btn btn-danger" onClick={this.deleteRecipe}>
                Delete Recipe
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Recipe);
