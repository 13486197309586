import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import RecipeForm from './recipe/RecipeForm';
import withRouter from '../utils/withRouter';

class NewRecipe extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values) {
    const token = document.querySelector(`meta[name="csrf-token"]`).content;
    const response = await fetch('/api/v1/recipes', {
      method: 'POST',
      headers: {
        'x-csrf-token': token,
        'content-type': 'application/json'
      },
      body: JSON.stringify(values)
    });

    if (response.ok) {
      const { id } = await response.json()
      this.props.router.navigate(`/recipe/${id}`)
    }
  }

  render() {
    return (
      <div className="container mt-5 mb-3">
        <div className="row col-sm-12 col-lg-6 offset-lg-3">
          <h1 className="fw-light mb-3">
            Add a new recipe!
          </h1>
        </div>
        <RecipeForm onSubmit={this.onSubmit} />
        <Link to="/recipes" className="btn btn-link mt-3 ps-1">
          Back to recipes
        </Link>
      </div>
    );
  }
}

export default withRouter(NewRecipe);